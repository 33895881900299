import Request from '@/assets/js/requests.js'
import Constants from '../../config.local.js'
import Common from '@/assets/js/common.js'
import ContactTransformer from '@/assets/js/dexie/transformers/ContactTransformer.js'
import HorseContactTransformer from '@/assets/js/dexie/transformers/HorseContactTransformer.js'
import TiersContactTransformer from '@/assets/js/dexie/transformers/TiersContactTransformer.js'

var ContactMixin = {
	data() {
		return {
		}
	},
	methods: {
		addContact: async function(params) {
			const contact_id = Common.getNegativeId()
			return this.$storage.db.t('contact')
			.then(table => {
				return table.add({
					contact_id: contact_id,
					contact_valide: 1,
					contact_lastname: params.contact_lastname,
					contact_firstname: params.contact_firstname,
					contact_civility: params.contact_civility,
					contact_mail: params.contact_mail,
					contact_address1: params.contact_address1,
					contact_address2: params.contact_address2,
					contact_postalcode: params.contact_postalcode,
					contact_town: params.contact_town,
					contact_country: params.contact_country,
					contact_operateur: params.contact_operateur ? 1 : 0,
					contact_archive: 0,
					contact_num_ordre: params.contact_num_ordre,
					contact_color: params.contact_color,
					contact_abbreviation: params.contact_abbreviation,
					contact_datenaissance: params.contact_datenaissance,
					contact_commentaire: params.contact_commentaire
				})
			})
			.then(col => {
				return contact_id
			})
		},

		editContact: async function(contact_id, params) {
			return this.$storage.db.t('contact')
			.then(table => {
				return table.update(parseInt(contact_id), params)
			})
		},

		getAllContact: async function(operateur = false) {
			if(operateur) {
				return this.$storage.db.t('contact')
	            .then(table => {
	                return table.where({
	                	contact_operateur: 1,
	                	contact_archive: 0
	                })
	            })
	            .then(col => {
	                return col.transform(new ContactTransformer('withPhone'))
	            })
			} 
			else {
				return this.$storage.db.t('contact')
	            .then(table => {
	                return table.where({contact_archive: 0})
	            })
	            .then(col => {
	                return col.transform(new ContactTransformer('withPhone'))
	            })
	        }
		},

		getAllArchivedContact: async function() {
			return this.$storage.db.t('contact')
            .then(table => {
                return table.where({contact_archive: 1})
            })
            .then(col => {
                return col.transform(new ContactTransformer('withPhone'))
            })
		},

		getContactByHorse: async function(horse_id, heavy = true) {
            const contact = await this.$storage.db.t('horse_contact')
            .then(table => {
                return table.where({horsecontact_valide: 1, horsecontact_horse: parseInt(horse_id)})
            })
            .then(col => {
            	return col.transform(new HorseContactTransformer(heavy ? 'withContactAndFonction' : null))
            })

            return contact
        },

		getContactByTiers: async function(tiers_id) {
            return this.$storage.db.t('tiers_contact')
            .then(table => {
                return table.where({tierscontact_valide: 1, tierscontact_tiers: parseInt(tiers_id)})
            })
            .then(col => {
            	return col.transform(new TiersContactTransformer('withContactAndFonction'))
            })
        },

        getHorseFemelleByContact: async function(contact_id) {
        	const contact = await this.$storage.db.t('horse_contact')
            .then(table => {
                return table.where({horsecontact_valide: 1, horsecontact_contact: parseInt(contact_id)})
            })
            .then(col => {
                return col.transform(new HorseContactTransformer('withHorse'))
            })

            const res = contact.filter(cont => cont.horse.horse_sexe == "F")

            return contact.map(cont => cont.horse)
        },

		invalidContact: async function(contact_ids, horse_id = null, tiers_id = null) {
			// await this.$storage.db.transaction(
			// 	'rw',                
			// 	['tiers_contact','horse_contact'],
			// 	() => {
					// Si c'est un contact dans la fiche tiers
					if(tiers_id) {
						await this.$storage.db.t('tiers_contact')
						.then(table => {
							return table.where({
								tierscontact_tiers: parseInt(tiers_id)
							})
							.and(tierscontact => contact_ids.includes(tierscontact.tierscontact_contact))
							.invalid()
						})
					}
					// Si c'est un contact dans la fiche horse
					else if(horse_id) {
						await this.$storage.db.t('horse_contact')
						.then(table => {
							return table.where({
								horsecontact_horse: parseInt(horse_id)
							})
							.and(horsecontact => contact_ids.includes(horsecontact.horsecontact_contact))
							.invalid()
						})
					}
			// 	}
			// )
		},

		archiveContacts: function(contact_ids) {
			return this.$storage.db.transaction(
                'rw',
                ['contact'],
                () => {
					this.$storage.db.t('contact')
					.then(table => {
						return table.where('contact_id')
							.anyOf(contact_ids)
							.modify({contact_archive: 1})
					})
                }
            )
		},

		unarchiveContacts: function(contact_ids) {
			return this.$storage.db.transaction(
                'rw',
                ['contact'],
                () => {
					this.$storage.db.t('contact')
					.then(table => {
						return table.where('contact_id')
							.anyOf(contact_ids)
							.modify({contact_archive: 0})
					})
                }
            )
		},

		getContactByTiersAndByFonction: async function(tiers_id, fonction_code) {
			const contacts = await this.getContactByTiers(tiers_id)

			let mails = [];
			contacts.forEach(contact => {
				if(contact.fonctions) {
					contact.fonctions.forEach(fonction => {
						if(fonction.fonction_code === fonction_code && contact.contact.contact_mail != '') {
							mails.push(contact.contact.contact_mail)
						}
					})
				}
			})

			return mails
        },

		getContactById: async function(contact_id) {
			return this.$storage.db.t('contact')
			.then(table => {
				return table.get(parseInt(contact_id))
			}).then(contact => {
				return ContactTransformer.process(contact, 'withPhone')
			})
		},

		getTiersByContact: async function(contact_id) {
			return this.$storage.db.t('tiers_contact')
            .then(table => {
                return table.where({
					tierscontact_valide: 1, 
					tierscontact_contact: parseInt(contact_id)
				})
            })
            .then(col => {
            	return col.transform(new TiersContactTransformer('withTiersAndFonction'))
            })
		},

		getHorsesByContact: async function(contact_id) {
			return this.$storage.db.t('horse_contact')
            .then(table => {
                return table.where({
					horsecontact_valide: 1, 
					horsecontact_contact: parseInt(contact_id)
				})
            })
            .then(col => {
            	return col.transform(new HorseContactTransformer('withHorseAndFonction'))
            })
		}
	}
}

export default ContactMixin
